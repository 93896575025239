import {Controller} from "stimulus";
import {setFormattedPrice, setSelectedValueEnum} from "./helpers";

export default class extends Controller {
  static targets = [ "style", "size", "displayRow", "displayName", "displayPrice", "priceHeight", "participantCount"]
  static values = { total: Number }

  totalValueChanged() {
    if (document.querySelector('#pricingTotal')?.pricing) // race condition on initial paint
      document.querySelector('#pricingTotal').pricing.calcTotal()
  }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element

    console.log('platform connected', this.identifier)
    this.toggleStyle()
  }

  getOptionValueByText(target, ps) {
    const options = target.options;
    let value = null;
    for (let i = 0; i < options.length; i++) {
        if (options[i].text == ps) {
            value = options[i].value;
            break;
        }
     }
    return value;
  }

toggleStyle() {
   // console.log('platform toggle style', this.styleTarget.value)
    let platformStyleValue = this.getOptionValueByText(this.styleTarget, 'No Platform');
    if(this.styleTarget.value !== platformStyleValue)
        this.displayRowTarget.classList.remove('d-none');
    else
        this.displayRowTarget.classList.add('d-none');
    this.toggleSize();
}

  toggleSize() {
    // console.log('platform toggle size', this.sizeTarget.value, this.styleTarget.value)

    // pull platform style and then lookup in the enum for our selected options on sizeTarget
    const selectedSizeTarget = this.sizeTarget.selectedOptions[0]
    const selectedPlatformStyleOption = this.styleTarget.selectedOptions[0]
    let platformStyleValue = this.getOptionValueByText(this.styleTarget, 'No Platform');
    if(selectedSizeTarget.dataset['price'] && this.styleTarget.value !== platformStyleValue) {
      // console.log(selectedSizeTarget)
      const platformStyleValue = selectedPlatformStyleOption.textContent
      this.totalValue = JSON.parse(selectedSizeTarget.dataset['price'])[platformStyleValue]

      setFormattedPrice(this.displayPriceTarget, this.totalValue)

    }
    this.displayNameTarget.innerText = `(${selectedSizeTarget.text})`
  }

  togglePriceHeight() {
    // console.log('platform toggle price height', this.priceHeightTarget.value)
    let quantity = null
    switch(document.querySelectorAll('div[data-participants-id-value]').length.toString()) {
      case '1':
        quantity = '1'
        break;
      case '2':
        quantity = '2'
        break;
      default:
        quantity = 'more'
        break;
    }
    const selectedPriceHeight = this.priceHeightTarget.selectedOptions[0]
    const selectedPlatformSize = selectedPriceHeight.dataset['platformSize']
    // console.log(selectedPlatformSize, quantity)
    // console.log(JSON.parse(selectedPlatformSize)[quantity])
    if(selectedPlatformSize) {
      // console.log('setting selected platform size based on price height', JSON.parse(selectedPlatformSize)[quantity])
      setSelectedValueEnum(this.sizeTarget, JSON.parse(selectedPlatformSize)[quantity])
    }

    this.toggleSize()
  }
}
