import {Controller} from "stimulus";
import {uuidv4} from "./helpers";
import {show, hide} from './helpers'

export default class extends Controller {
  static targets = [ "type", "scanLengthRow", "email", "lengthRow", "firstName", "lastName", "heightFeet", "heightInches", "poseHeightFeet",
    "poseHeightInches", "printHeight", "priceHeight", "heightWrapper", "potentialParticipants", "emailWrapper"]
  static values = { id: String, priceType: String }

  priceTypeValueChanged() {
    console.log('pricetype value changed', this.priceTypeValue, this.heightWrapperTargets)
    if (this.pricingRow())
      this.updateRelated()

    if(this.priceTypeValue === 'pet')
      this.heightWrapperTargets.map(t=> t.innerText = 'Nose to Butt Length')
    else
      this.heightWrapperTargets.map(t=> t.innerText = 'Standing Height')
  }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
    console.log('participants connected', this.identifier)

    // check if we have an ID for this element - if we do we don't need to create anything - we're working with a persisted item.
    // otherwise we need to add our rows to support this new element
    // add 2 rows - one to the sizing table & 1 to the pricing table

    this.priceTypeValue = this.typeRadioValue()

    if (!this.hasIdValue) {
      this.idValue = uuidv4() // used to find our other items on the page when we need to update them

      let pricing = document.getElementById('participantPricingTemplate').content.firstElementChild.cloneNode(true)
      pricing.dataset.pricingRowParticipantIdValue = this.idValue
      document.getElementById('pricingTotal').insertBefore(pricing, document.getElementById('platformSizePrice'))
      let sizing = document.getElementById('participantSizeTemplate').content.firstElementChild.cloneNode(true)
      sizing.dataset.scalingRowParticipantIdValue = this.idValue
      document.getElementById('participantSizeTable').appendChild(sizing)

      if(this.pricingTotalController())
        this.pricingTotalController().participantCountValue += 1
    }

    // this.toggleType() // to display on load if pet is selected
    this.updateRelated()
  }

  remove() {
    console.log('participants removed')
    // remove sizing & pricing rows

    document.querySelector(`[data-pricing-row-participant-id-value="${this.idValue}"].pricing-row`).remove()
    document.querySelector(`[data-scaling-row-participant-id-value="${this.idValue}"].scaling-row`).remove()
    this.pricingTotalController().participantCountValue -= 1
    this.mainScalingController().calcScaleParticipantsItems() // recalc our dropdown in the scaling list to remove
    this.updateRange(true)
  }

  mainScalingController(){
    return document.querySelector('#scalingController')?.scaling
  }

  name() {
    return this.firstNameTarget.value + ' ' + this.lastNameTarget.value
  }

  setPotential() {
    console.log('setting potential participant info', this.potentialParticipantRadioValue())

    this.emailTarget.value = this.potentialParticipantRadioValue().dataset.email
    this.firstNameTarget.value = this.potentialParticipantRadioValue().dataset.firstName
    this.lastNameTarget.value = this.potentialParticipantRadioValue().dataset.lastName
    this.heightFeetTarget.value = this.potentialParticipantRadioValue().dataset.heightFeet
    this.heightInchesTarget.value = this.potentialParticipantRadioValue().dataset.heightInches
    this.poseHeightFeetTarget.value = this.potentialParticipantRadioValue().dataset.heightFeet
    this.poseHeightInchesTarget.value = this.potentialParticipantRadioValue().dataset.heightInches

    this.updateRelated()
  }

  // separate than update related so we can only try to update the pose height here when we're changing the height
  updateHeight() {
    // set pose height to height feet regardless if it's blank so that changing it updates the other
    if (this.heightFeetTarget.value && this.heightInchesTarget.value) {
      this.poseHeightFeetTarget.value = this.heightFeetTarget.value
      this.poseHeightInchesTarget.value = this.heightInchesTarget.value
      this.updateRange()
    }
    this.updateRelated()
  }

  updateRange(remove) {
    let feetInputs = document.querySelectorAll('input[name^="order_item[participants_attributes]["][name$="[height_feet]"]');
    let inchesInputs = document.querySelectorAll('input[name^="order_item[participants_attributes]["][name$="[height_inches]"]');

    feetInputs = Array.from(feetInputs);
    inchesInputs = Array.from(inchesInputs);

    if (remove !== undefined) {
      const feetElementToRemove = this.heightFeetTarget;
      const inchesElementToRemove = this.heightInchesTarget;
      feetInputs = feetInputs.filter(element => element !== feetElementToRemove);
      inchesInputs = inchesInputs.filter(element => element !== inchesElementToRemove);
    }

    let maxHeightInInches = 0;
    feetInputs.forEach((feetInput, index) => {
      const inchesInput = inchesInputs[index];

      if (feetInput && inchesInput) {
        const totalHeightInInches = Number(feetInput.value) * 12 + Number(inchesInput.value);

        if (totalHeightInInches > maxHeightInInches) {
          maxHeightInInches = totalHeightInInches;
        }
      }
    });

    const isFragile = document.querySelector('#scalingController').scaling.rangeStartTarget.getAttribute('data-fragile') === 'true'
    document.querySelector('#scalingController').scaling.rangeStartTarget.textContent = (maxHeightInInches / (isFragile ? 7 : 4)).toFixed(2);
    document.querySelector('#scalingController').scaling.rangeEndTarget.textContent = (maxHeightInInches / 14).toFixed(2);
  }

  updateRelated() {
    if(!(!this.pricingRow() || !this.scalingRow())) {
      console.log('updating related fields')
      this.pricingRow().nameTarget.innerText = '(' + this.name() + ')'

      this.scalingRow().nameTarget.innerText = this.name()

      if (this.heightFeetTarget.value && this.heightInchesTarget.value) {
        this.scalingRow().refHeightTarget.value = Number(this.heightFeetTarget.value) * 12 + Number(this.heightInchesTarget.value)
        this.scalingRow().heightTarget.innerText = this.heightFeetTarget.value + "' " + this.heightInchesTarget.value + '"'

        this.scalingRow().poseHeightTarget.innerText = this.poseHeightFeetTarget.value + "' " + this.poseHeightInchesTarget.value + '"'
        this.scalingRow().poseHeightCalculatedTarget.value = Number(this.poseHeightFeetTarget.value) * 12 + Number(this.poseHeightInchesTarget.value)

        this.scalingRow().calculatePrintHeight()

        this.pricingRow().printHeightTarget.value = this.scalingRow().printHeightValue
        this.printHeightTarget.value = this.scalingRow().printHeightValue
      }

      this.pricingRow().priceTypeTarget.innerText = this.priceTypeValue
    }

    console.log('update related', this.scaling())
    this.scaling()?.toggleFigureSize()

    // if (this.mainScalingController())
    this.mainScalingController().calcScaleParticipantsItems() // recalc our dropdown in the scaling list
  }

  scaling() {
    return document.getElementById('scalingController')?.scaling
  }

  scalingRow() {
    return document.querySelector(`[data-scaling-row-participant-id-value="${this.idValue}"].scaling-row`)?.scalingRow
  }

  pricingRow() {
    return document.querySelector(`[data-pricing-row-participant-id-value="${this.idValue}"].pricing-row`)?.pricingRow
  }

  pricingTotalController() {
    return document.querySelector('#pricingTotal')?.pricing
  }

  toggleType(event) {
    event.target.value === 'pet' ? this.priceTypeValue = 'pet' : this.priceTypeValue = 'people'


    if(event.target.value === 'child' || event.target.value === 'pet')
      hide(this.emailWrapperTarget)
    else
      show(this.emailWrapperTarget)
  }

  typeRadioValue() {
    if(document.getElementById('order_item_product_id') && document.getElementById('order_item_product_id').selectedOptions[0]?.dataset?.type === 'Ornament')
      return 'ornaments'
    else if(document.getElementById('order_item_product_id') && document.getElementById('order_item_product_id').selectedOptions[0]?.dataset?.type === 'Digital')
      return 'digital'
    for(let i = 0; i < this.typeTargets.length; i++) {
      if(this.typeTargets[i].checked)
        return this.typeTargets[i].value  === 'pet' ? 'pet' : 'people'
    }
  }

  potentialParticipantRadioValue() {
    for(let i = 0; i < this.potentialParticipantsTargets.length; i++) {
      if(this.potentialParticipantsTargets[i].checked)
        return this.potentialParticipantsTargets[i]
    }
  }
}
